import * as PIXI from "pixi.js";

export default {
    computed: {
        observerConfig() {
            return {
                callBack: this.render,
                animateOnObserve: true
            };
        }
    },
    methods: {
        mapWineAnimation() {
            let ease = this.CustomEase.create(
                "custom",
                "M0,0,C0.104,0.204,0,1,1,1"
            );
            let duration = 0.8;
            this.gsap.fromTo(
                ".wine-icon",
                {
                    rotate: 60
                },
                {
                    rotate: 0,
                    delay: 1.4,
                    duration,
                    ease
                }
            );
            this.gsap.fromTo(
                ".wine-icon circle",
                {
                    scale: 0
                },
                {
                    scale: 1,
                    delay: 1.4,
                    duration,
                    ease
                }
            );
        },
        render() {
            const app = new PIXI.Application({
                backgroundColor: 0x000000,
                backgroundAlpha: 0,
                resizeTo: window
            });
            if (document.getElementById(this.id) !== null) {
                document.getElementById(this.id).appendChild(app.view);
            }
            app.view.height = 242;
            app.view.width = innerWidth;
            this.mapWineAnimation();

            const perlinTexture = PIXI.Texture.from(
                require("@/assets/images/perlin.jpg")
            );

            // Build geometry.
            const geometry = new PIXI.Geometry()
                .addAttribute(
                    "aVertexPosition", // the attribute name
                    [
                        0,
                        0, // x, y
                        innerWidth,
                        0, // x, y
                        innerWidth,
                        242,
                        0,
                        242
                    ], // x, y
                    2
                ) // the size of the attribute
                .addAttribute(
                    "aUvs", // the attribute name
                    [
                        0.2,
                        0.2, // u, v
                        1,
                        0.5, // u, v
                        1,
                        1,
                        0,
                        1
                    ], // u, v
                    2
                ) // the size of the attribute
                .addIndex([0, 1, 2, 0, 2, 3]);

            const vertexSrc = `

    precision mediump float;

    attribute vec2 aVertexPosition;
    attribute vec2 aUvs;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    varying vec2 vUvs;

    void main() {

        vUvs = aUvs;
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);

    }`;

            const fragmentNoiseSrc = `
    precision mediump float;
    varying vec2 vUvs;
    uniform float zoom;
    uniform float mouse;
    uniform float limit;
    uniform vec3 color;
    uniform sampler2D noise;

    void main()
    {
        //Generate a simple grid.
        //Offset uv so that center is 0,0 and edges are -1,1
        vec2 uv = (vUvs-vec2(0.5))*2.0;
        vec2 gUv = floor(uv*zoom);
        vec4 color1 = vec4(vec3(color), 1.0);
        vec4 color2 = vec4(vec3(color), 1.0);
        vec4 outColor = mod(gUv.x + gUv.y, 2.) < 0.5 ? color1 : color2;

        float color = texture2D(noise, vUvs).r;
        color = step(limit, color);

        gl_FragColor = mix(vec4(0.,0.,0.,0.),outColor,color);

    }`;

            const noiseUniforms = {
                limit: 0,
                zoom: 5,
                noise: perlinTexture,
                color: this.color
            };
            const noiseShader = PIXI.Shader.from(
                vertexSrc,
                fragmentNoiseSrc,
                noiseUniforms
            );
            const noiseQuad = new PIXI.Mesh(geometry, noiseShader);
            app.stage.addChild(noiseQuad);

            let time = 12;

            app.ticker.add(() => {
                time += 1 / 60;
                let endTime = Math.round((time + Number.EPSILON) * 100) / 100;

                if (endTime === 14) {
                    app.ticker.stop();
                    return;
                }
                noiseQuad.shader.uniforms.limit = Math.sin(time) * 0.35 + 0.5;
                noiseQuad.shader.uniforms.color = this.color;
                // waveQuad.shader.uniforms.time = time;

                // app.Renderer(waveQuad, waveTexture);
            });
        }
    }
};
