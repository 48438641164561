<template>
    <div
        data-scroll
        :data-scroll-id="name"
        id="world-map"
        :class="{ dark: !lightMode }"
        v-observer:callback="observerConfig"
        class="world-map"
    >
        <text-wipe id="map-wipe" :color="color" :header="header" />
        <div>
            <img
                src="../../assets/images/worldMap.png"
                class="world-image"
                alt=""
            />
            <svg
                data-scroll
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1921.3 839.92"
                xmlns:v="https://vecta.io/nano"
            >
                <g
                    fill="none"
                    stroke="#e5b676"
                    stroke-linecap="round"
                    stroke-width="2"
                >
                    <path
                        d="M1146.805 234c-207-244-557.84-167.08-703.99-4m703.99 4c-132.45-263.23-461.68-307.85-671.68-110.92M1146.805 234c-60.645-59.037-128.01-78.381-193.59-73.6m236.99 73.6c54.025-39.92 184.79-61.222 264.08 26.353"
                        opacity=".35"
                    />
                    <path
                        d="M1190.205 234c60.645-59.037 272.96-154.71 433.31 11.559M1190.205 234c103.6-91 471.17-127.04 471.17 386.12"
                        opacity=".35"
                    />
                    <path
                        d="M1190.205 234c47.442-44.226 44.493-80.951 22.947-100.84M1146.805 234c-13.416-13.416-29.712-3.3-29.712 6.966"
                        opacity=".35"
                    />
                </g>
                <defs>
                    <path
                        id="B"
                        d="M1159.628 232.728c-.723.015-1.442-.102-2.123-.346a4.02 4.02 0 0 1-1.509-.98 4.01 4.01 0 0 1-.9-1.525c-.203-.638-.303-1.304-.295-1.973-.008-.662.099-1.321.314-1.947a4.14 4.14 0 0 1 .929-1.518c.432-.437.955-.773 1.531-.986.679-.246 1.398-.366 2.12-.352a7.61 7.61 0 0 1 1.432.119c.354.062.699.17 1.025.323.242.11.453.276.618.484a.99.99 0 0 1 .2.6.9.9 0 0 1-.109.432c-.08.143-.192.264-.327.356a1.7 1.7 0 0 1-.532.24 2.76 2.76 0 0 1-.73.086 2.63 2.63 0 0 0-.1-.73c-.058-.209-.16-.404-.3-.57-.139-.161-.313-.287-.509-.368-.23-.092-.476-.137-.724-.131a2.61 2.61 0 0 0-1.22.263 2.11 2.11 0 0 0-.82.769c-.229.384-.385.806-.461 1.246-.102.557-.15 1.122-.144 1.688a8.42 8.42 0 0 0 .151 1.681 3.42 3.42 0 0 0 .487 1.233 2.18 2.18 0 0 0 .871.759c.411.184.859.273 1.31.259q.243 0 .487-.019c.159-.012.318-.036.474-.07v-1.928a.93.93 0 0 0-.237-.73 1.04 1.04 0 0 0-.7-.2h-.173v-.692h4.125v.692h-.154a1.12 1.12 0 0 0-.346.048.52.52 0 0 0-.243.163.73.73 0 0 0-.141.3 2.08 2.08 0 0 0-.045.471v2.152a7.87 7.87 0 0 1-1.55.528 7.62 7.62 0 0 1-1.682.173z"
                    />
                    <path
                        id="C"
                        d="M1181.369 232.728c-.496.011-.992-.045-1.473-.167-.338-.086-.658-.231-.945-.429a1.55 1.55 0 0 1-.506-.589c-.097-.202-.148-.423-.151-.647-.006-.197.036-.393.125-.57.08-.15.194-.28.333-.378s.304-.172.474-.211c.181-.043.367-.065.554-.064a3.29 3.29 0 0 0 .135.99c.077.259.203.5.372.711a1.52 1.52 0 0 0 .567.429 1.78 1.78 0 0 0 .721.144c.233.004.465-.033.685-.109a1.52 1.52 0 0 0 .512-.3c.139-.125.248-.28.32-.452.073-.177.111-.368.109-.56a1.27 1.27 0 0 0-.135-.593 1.54 1.54 0 0 0-.407-.48c-.212-.166-.442-.308-.685-.423q-.413-.2-.964-.432c-.401-.16-.788-.354-1.156-.58a3.21 3.21 0 0 1-.759-.644c-.186-.22-.327-.474-.413-.749a2.98 2.98 0 0 1-.125-.884 2.4 2.4 0 0 1 .243-1.089c.161-.323.391-.607.673-.833a3.08 3.08 0 0 1 1.018-.532 4.24 4.24 0 0 1 1.281-.186 5.74 5.74 0 0 1 1.249.119c.304.062.597.171.868.323a1.43 1.43 0 0 1 .509.484c.11.181.168.388.167.6.001.149-.033.296-.1.429a.96.96 0 0 1-.3.346 1.54 1.54 0 0 1-.506.231 2.7 2.7 0 0 1-.708.083c-.002-.2-.027-.399-.074-.593a1.98 1.98 0 0 0-.24-.589c-.11-.18-.257-.334-.432-.452-.192-.124-.416-.186-.644-.179a1.61 1.61 0 0 0-.5.077 1.17 1.17 0 0 0-.413.231 1.1 1.1 0 0 0-.279.381 1.26 1.26 0 0 0-.1.522 1.49 1.49 0 0 0 .09.519 1.31 1.31 0 0 0 .33.477 3.14 3.14 0 0 0 .66.468 9.9 9.9 0 0 0 1.086.5 8.21 8.21 0 0 1 1.147.564 3.58 3.58 0 0 1 .778.618 2.07 2.07 0 0 1 .442.724c.095.281.142.577.138.874.005.401-.081.799-.25 1.163a2.56 2.56 0 0 1-.717.906 3.45 3.45 0 0 1-1.124.589c-.479.147-.979.219-1.48.212z"
                    />
                    <path
                        id="D"
                        d="M1171.832 223.324l1.813 5.009q.115.307.215.625.1.318.183.605.083.288.147.532.064.244.1.416.051-.237.109-.525.058-.288.125-.605.067-.317.141-.65.074-.333.151-.647l.692-2.831q.019-.077.042-.179.023-.102.042-.2.019-.1.029-.186c.006-.046.009-.092.01-.138.019-.184-.056-.364-.2-.48a1.13 1.13 0 0 0-.637-.141h-.3v-.692h3.41v.692h-.25a1.35 1.35 0 0 0-.339.038c-.099.026-.19.078-.263.151a1.05 1.05 0 0 0-.208.311 3.4 3.4 0 0 0-.183.525l-2.037 7.645h-1.492l-2.2-6.156-1.89 6.156h-1.627l-2.4-7.975a1.33 1.33 0 0 0-.141-.339c-.051-.084-.12-.158-.2-.215a.7.7 0 0 0-.256-.112c-.111-.023-.223-.033-.336-.032h-.173v-.692h4.381v.692h-.306a.95.95 0 0 0-.593.154c-.139.119-.214.297-.2.48a1.76 1.76 0 0 0 .042.317q.042.208.093.388l.878 3.03q.07.25.151.541.081.291.151.589.07.3.128.573.058.273.083.487.09-.423.2-.791.11-.368.221-.753l1.73-5.618z"
                    />
                </defs>
            </svg>
            <div class="content-container">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="list-item"
                >
                    <div class="list-item-content">
                        <div class="header-title" v-html="item.amount"></div>
                        <div class="down">
                            <span class="description">{{ item.title }}</span>
                            <img
                                @mouseover="mouseOver(index)"
                                @click="mouseOver(index)"
                                @mouseleave="mouseLeave"
                                @mouseup="mouseLeave"
                                @mouseout="mouseLeave"
                                src="@/assets/images/about.png"
                                class="icon"
                                alt=""
                            />
                            <div
                                :class="{ active: activeTooltipId === index }"
                                v-if="item.tooltip"
                                class="tooltip"
                            >
                                <div class="tooltip-inner">
                                    {{ item.tooltip }}
                                </div>
                                <div class="tooltip-arrow"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-container mobile-container">
                <div class="wrapper-mobile">
                    <div
                        v-for="(item, index) in itemsStart"
                        :key="index"
                        class="list-item"
                    >
                        <div class="list-item-content">
                            <div
                                class="header-title"
                                v-html="item.amount"
                            ></div>
                            <div class="down">
                                <span class="description">{{
                                    item.title
                                }}</span>
                                <img
                                    @mouseover="mouseOver(index)"
                                    @click="mouseOver(index)"
                                    @mouseleave="mouseLeave"
                                    @mouseup="mouseLeave"
                                    @mouseout="mouseLeave"
                                    src="@/assets/images/about.png"
                                    class="icon"
                                    alt=""
                                />
                                <div
                                    :class="{
                                        active: activeTooltipId === index
                                    }"
                                    v-if="item.tooltip"
                                    class="tooltip"
                                >
                                    <div class="tooltip-inner">
                                        {{ item.tooltip }}
                                    </div>
                                    <div class="tooltip-arrow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wrapper-mobile">
                    <div
                        v-for="(item, index) in itemsEnd"
                        :key="index"
                        class="list-item"
                    >
                        <div class="list-item-content">
                            <div
                                class="header-title"
                                v-html="item.amount"
                            ></div>
                            <div class="down">
                                <span class="description">{{
                                    item.title
                                }}</span>
                                <img
                                    @mouseover="mouseOver(index + 2)"
                                    @click="mouseOver(index + 2)"
                                    @mouseleave="mouseLeave"
                                    @mouseup="mouseLeave"
                                    @mouseout="mouseLeave"
                                    src="@/assets/images/about.png"
                                    class="icon"
                                    alt=""
                                />
                                <div
                                    :class="{
                                        active: activeTooltipId === index + 2
                                    }"
                                    v-if="item.tooltip"
                                    class="tooltip"
                                >
                                    <div class="tooltip-inner">
                                        {{ item.tooltip }}
                                    </div>
                                    <div class="tooltip-arrow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Wipe from "@/mixins/Wipe";
import textWipe from "@/components/custom/textWipe";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            id: "world-map",
            activeTooltipId: null
        };
    },
    mixins: [Wipe],
    components: {
        textWipe
    },
    computed: {
        color() {
            return this.lightMode ? [1, 1, 1] : [0.039, 0.078, 0.11];
        },
        header() {
            return this.config.header || {};
        },
        items() {
            return this.data?.list?.map(item => ({
                amount: item.amount,
                title: item.title,
                tooltip: item.tooltip
            }));
        },
        itemsStart() {
            return this.items?.slice(0, 2);
        },
        itemsEnd() {
            return this.items?.slice(2, 4);
        }
    },
    methods: {
        mouseOver(index) {
            this.activeTooltipId = index;
        },
        mouseLeave() {
            this.activeTooltipId = null;
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.07, 0.27, 0.15, 1);

.world-map /deep/ {
    margin-top: 144px;
    position: relative;

    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        margin-top: 89px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding-bottom: 40px;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 34px;
        padding-bottom: 120px;
    }

    .text-wipe {
        // padding-left: 195px;
        padding-bottom: 48px;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            // padding-left: 165px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            // padding-left: 128px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            // padding-left: 47px;
            padding-bottom: 34px;
        }
        @media only screen and (max-width: 767px) {
            padding-left: 16px;
            padding-bottom: 34px;
        }

        .content {
            display: flex;
            align-items: center;

            .header {
                color: #0a141c;
                font-size: 46px;

                @media only screen and (max-width: 1365px) and (min-width: 768px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }

                &.colored {
                    color: #e5b676;
                }
            }
        }
    }

    .stroke-animation {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        transition: all 0.8s 1.4s $ease-out;
    }

    svg {
        width: 100%;
        height: auto;
    }

    .world-image {
        position: absolute;
        width: 100%;
    }

    .wine-icon {
        g {
            opacity: 0;
            transition: all 0.6s 1.8s $ease-out;
        }
    }

    .content-container {
        padding: 0 225px 0 195px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 30%;
        width: 100%;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 0 165px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 0 128px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            padding: 0 47px;
        }
        @media only screen and (max-width: 1023px) {
            display: grid;
            bottom: 0;
            justify-content: space-between;
            grid-template-columns: repeat(2, 1fr);
            padding: 0 114px;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
        @media only screen and (max-width: 767px) {
            padding: 0 50px;
            display: none;
            width: 100%;
        }
        @media only screen and (max-width: 413px) {
            padding: 0 16px;
            width: 100%;
        }

        &.mobile-container {
            display: none;
            width: fit-content;
            padding: 0 16px;
            @media only screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
            }

            .wrapper-mobile {
                width: 100%;
                display: flex;
                align-items: flex-start;

                &:nth-child(2) {
                    margin-top: 34px;
                }

                .list-item {
                    &:nth-child(2) {
                        @media only screen and (max-width: 767px) {
                            .tooltip {
                                // right: 132px;

                                .tooltip-arrow {
                                    left: unset;
                                    right: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .list-item {
            display: flex;
            will-change: transform;
            @media only screen and (max-width: 1023px) {
                align-items: center;
                width: fit-content;
                &:nth-child(n + 3) {
                    margin-top: 34px;
                }
            }
            @media only screen and (max-width: 767px) {
                &:nth-child(2n) {
                    margin-left: 55px;
                }
            }

            .list-item-content {
                display: flex;
                flex-direction: column;
            }
        }

        .header-title {
            font-size: 55px;
            font-style: normal;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 10px;
            text-decoration-color: #e5b676;
            letter-spacing: 1.3px;
            transition: all 0.6s $ease-out;
            text-align: left;
            display: block;
            will-change: transform;
            -webkit-font-smoothing: antialiased;
            backface-visibility: hidden;

            span {
                font-size: 55px;
            }

            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                font-size: 55px;
                span {
                    font-size: 34px;
                }
            }
            @media only screen and (max-width: 767px) {
                font-size: 34px;
                span {
                    font-size: 21px;
                }
            }
            @media only screen and (max-width: 413px) {
                font-size: 21px;
            }
        }

        .down {
            display: flex;
            align-items: center;
            text-align: left;
            position: relative;
            width: fit-content;
            margin-top: 15px;

            .description {
                font-size: 34px;
                color: #e5b676;
                letter-spacing: 1.3px;
                -webkit-font-smoothing: antialiased;
                backface-visibility: hidden;
                will-change: transform;

                @media only screen and (max-width: 1440px) and (min-width: 768px) {
                    font-size: 21px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 500px) {
                    letter-spacing: 1px;
                }
                @media only screen and (max-width: 413px) {
                    font-size: 14px;
                }
            }

            .icon {
                margin-left: 10px;
                cursor: pointer;
                @media only screen and (max-width: 1440px) and (min-width: 1025px) {
                    width: 20px;
                    margin-top: 0;
                }
                @media only screen and (max-width: 1024px) {
                    width: 18px;
                    margin-top: 0;
                }
            }
        }
    }

    .tooltip {
        display: block !important;
        position: absolute;
        right: 0;
        bottom: 120%;
        // transform: translateX(calc(100% - 26px));
        opacity: 0;
        transition: all 0.4s $ease-out;
        pointer-events: none;
        will-change: transform;
        z-index: 9;

        .tooltip-inner {
            background: #740b43;
            color: white;
            border-radius: 5px;
            text-align: left;
            padding: 14.5px 17px 14.5px 12px;
        }

        .tooltip-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            border-color: #740b43;
            z-index: 1;
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            right: 6px;
            margin-top: 0;
            margin-bottom: 0;
        }

        &.active {
            opacity: 1;
        }
    }
}

.world-map.observed {
    .stroke-animation {
        stroke-dashoffset: 300;

        &#Path_510 {
            stroke-dashoffset: 961;
        }

        &#Path_503 {
            stroke-dashoffset: 209;
        }

        &#Path_504 {
            stroke-dashoffset: 209;
        }

        &#Path_505 {
            stroke-dashoffset: 787;
        }

        &#Path_509 {
            stroke-dashoffset: 883;
        }

        &#Path_507 {
            stroke-dashoffset: 522;
        }

        &#Path_506 {
            stroke-dashoffset: 712;
        }

        &#Path_508 {
            stroke-dashoffset: 220;
        }
    }

    .wine-icon {
        g {
            opacity: 1;
        }
    }
}

.world-map.dark /deep/ {
    .list-item-content {
        .header-title {
            color: white !important;
        }
    }

    .text-wipe {
        .header {
            color: white !important;
        }
    }
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}
</style>
